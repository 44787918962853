export const CONTENT_TYPES = {
  armedforces: 'armedforcespage',
  'militant organizations': 'militaryorganizationpage',
  news: 'newspage',
  reports: 'reportpage',
  weapons: 'weapondetailpage',
};

export const CUSTOM_SECTION_TYPE = 'custom_section';
export const IMAGE_SECTION_TYPE = 'images';
